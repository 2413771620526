//
// Main Javascript file
// Author: Sebastian Adel (http://www.freshmonday.ro)
//

/**
 *  Global Vars
 */
// Global
// var $viewport                    = $('html, body');
// var $window                      = $(window);
// var windowHeight                 = $window.height();
// var windowHeightPadded           = windowHeight / 1.2;
// var $main                        = $('.main');
// var mainStateClass               = 'has-floating';

// Textarea
// var $textarea                    = $('.js-textarea');

// Header
var headerStateNavClass          = 'has-open-menu';
var $header                      = $('.js-header');

// Nav
var $nav                         = $('.js-nav');
var $navAction                   = $('.js-nav-action');
var navStateClass                = 'is-visible';
var navStateClass                = 'is-visible';

// Contact
var $contact                     = $('.js-contact');
var $contactContent              = $('.js-contact-content');
var $contactNotice               = $('.js-contact-notice');

// Booking
var $booking                     = $('.js-booking');
var $bookingContent              = $('.js-booking-two');
var $bookingNotice               = $('.js-booking-notice');

/**
 * [hotelimpero Object]
 * @type {Object}
 */
var hotelimpero = {

    init: function() {

        // Links actions
        hotelimpero.linksHandler();

        // Detect browsers
        hotelimpero.detectBrowser();

        // Navigation handler
        hotelimpero.navHandler();

        // Welcome slider
        hotelimpero.welcomeSlider();

        // Rooms Carousel
        hotelimpero.frontpageRoomsCarousel();

        // Square Photo Gallery Carousel
        hotelimpero.squarePhotoGalleryCarousel();
        hotelimpero.squareDoublePhotoGalleryCarousel();

        // Restaurant Slider
        hotelimpero.restaurantSlider();

        // Contact handler
        hotelimpero.contactHandler();

        // Booking handler
        hotelimpero.bookingHandler();

        // Fancybox
        hotelimpero.openFancyBox();

        // DatePicker
        hotelimpero.datePicker();

        // Custom Select
        hotelimpero.customSelect();

        // Form Validator
        hotelimpero.formValidation();

        // Open Room Booking
        hotelimpero.openRoomBooking();

        // Close Room Booking
        hotelimpero.closeRoomBooking();

    },

    // Links handler
    linksHandler: function() {
        // Initialize function
        function __init () {
            // Open in new window links with rel=external code
            $('a[rel="external"]').attr('target','_blank');
            // Prevent default action on # (hash) links
            $('a[href="#"]').on('click', function(e) { e.preventDefault(); });
        }

        // Initialize module
        return __init();
    },

    // Detect browsers
    detectBrowser: function() {
        // Initialize function
        function __init() {
            isIE = detectIE();
            // Add class to HTML element
            if (isIE) { $('html').addClass('ie '+isIE); }
        }

        // Detect IE
        function detectIE() {
            var ua      = window.navigator.userAgent;
            var msie    = ua.indexOf('MSIE ');
            var trident = ua.indexOf('Trident/');

            if (msie > 0) {
                // IE 10 or older => return version number
                return 'ie'+parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            if (trident > 0) {
                // IE 11 (or newer) => return version number
                var rv = ua.indexOf('rv:');
                return 'ie'+parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }
            // Other browser
            return false;
        }

        // Initialize module
        return __init();
    },

    // Navigation handler
    navHandler: function(action) {
        // Set variable to false if not defined
        action = action || null;

        // Initialize function
        function __init() {
            switch (action) {
                case 'navActions':
                    // Nav actions
                    navActions();
                break;
                case 'navClose':
                    // Nav close
                    navClose();
                break;
                case 'navOpen':
                    // Nav close
                    navOpen();
                break;
                default:
                // Nav actions
                navActions();
            }
        }
        // Nav actions
        function navActions() {
            $navAction.unbind('click').on('click', function(e) {
                e.preventDefault();
                if ($nav.length > 0) {
                    if ($header.hasClass(headerStateNavClass)) {
                        navClose();
                    } else {
                        navOpen();
                    }
                }
            });
        }
        // Nav open
        function navOpen() {
                $nav.addClass(navStateClass);
                $header.addClass(headerStateNavClass);
        }
        // Nav close
        function navClose() {
                $nav.removeClass(navStateClass);
                $header.removeClass(headerStateNavClass);
        }

        // Initialize module
        return __init();
    },

    // Contact handler
    contactHandler: function() {
        // Initialize function
        function __init() {
            if ($contact.length > 0) {
                // Vars
                var contactHeight = $contact.outerHeight();

                // Validate contact form
                $contact.h5Validate();

                // Process contact form
                $contact.submit(function(e) {
                    // Vars
                    var result = $contact.h5Validate('allValid');
                    var data;
                    var url;

                    if ( result === true ) {
                        // Serialize contact data
                        data = $(this).serialize();
                        // Get URL from action
                        url = $(this).attr('action');
                        method = $(this).attr('method');

                        // Send request
                        $.ajax({
                            url         : url,
                            data        : data,
                            type        : method,
                            cache       : false,
                            error       : function(result) {
                                // Log message
                                console.log(result);
                            },
                            success     : function(result) {
                                result = result || null;
                                // Log message
                                // console.log(result);
                                // Prevent resizing
                                $contact.css({'min-height': contactHeight + 'px'});
                                // Hide form content for 10s
                                $contactContent.velocity("fadeOut", { duration: 800 })
                                               .velocity("fadeIn", { delay: 10000, duration: 1000 });
                                // Show form notice for 9s
                                $contactNotice.velocity("fadeIn", { delay: 850, duration: 1500 })
                                               .velocity("fadeOut", { delay: 7000, duration: 800 });
                                // Clear fields
                                $contact.trigger('reset');
                            }
                        });
                    }
                    // Prevent actual form submit
                    e.preventDefault();
                });
            }
        }

        // Initialize module
        return __init();
    },

    // Booking handler
    bookingHandler: function() {
        // Initialize function
        function __init() {
            if ($booking.length > 0) {
                // Vars
                var bookingHeight = $booking.outerHeight();

                // Validate contact form
                $booking.h5Validate();

                // Process contact form
                $booking.submit(function(e) {
                    // Vars
                    var result = $booking.h5Validate('allValid');
                    var data;
                    var url;

                    if ( result === true ) {
                        // Serialize contact data
                        data = $(this).serialize();
                        // Get URL from action
                        url = $(this).attr('action');
                        method = $(this).attr('method');

                        // Send request
                        $.ajax({
                            url         : url,
                            data        : data,
                            type        : method,
                            cache       : false,
                            error       : function(result) {
                                // Log message
                                console.log(result);
                            },
                            success     : function(result) {
                                result = result || null;
                                // Log message
                                console.log(result);
                                // Prevent resizing
                                $booking.css({'min-height': bookingHeight + 'px'});
                                // Hide form content for 10s
                                $bookingContent.velocity("fadeOut", { duration: 800 });
                                // Show form notice for 9s
                                $bookingNotice.velocity("fadeIn", { delay: 850, duration: 1500 })
                                               .velocity("fadeOut", { delay: 7000, duration: 800 });
                                // Clear fields
                                $booking.trigger('reset');
                            }
                        });
                    }
                    // Prevent actual form submit
                    e.preventDefault();
                });
            }
        }

        // Initialize module
        return __init();
    },

    // datePicker Handler
    datePicker: function() {
        $('.js-datepicker').datetimepicker({
            timepicker:false,
            scrollInput:false,
            scrollMonth:false,
            scrollTime:false,
            format:'d/m/Y',
            minDate:'0', //yesterday is minimum date(for today use 0 or -1970/01/01)
            className: 'datepicker-container'
        });
    },

    // Form Validation Handler
    formValidation: function() {
        $('.js-form-validation').h5Validate();
    },

    // custom Select Handler
    customSelect: function() {
        $('.js-custom-select').select2({minimumResultsForSearch: -1});
    },

    // Frontpage Rooms Carousel
    frontpageRoomsCarousel: function() {
        $('.js-frontpage-rooms-carousel').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            },
            {
            breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
        });

    },

    // HomepageTextSlider
    welcomeSlider: function() {
        $('.js-welcome-slider').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true,
            autoplay: true,
            cssEase: 'linear'
        });
    },

    // Square Photo Gallery Carousel
    squarePhotoGalleryCarousel: function() {
        $('.js-square-gallery-carousel').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1150,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
            breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
            breakpoint: 450,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    dots: true
                }
            }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
        });

    },

    // Square Photo Gallery Carousel
    squareDoublePhotoGalleryCarousel: function() {
        $('.js-square-gallery-double-carousel').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1150,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    dots: true
                }
            },
            {
            breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
            breakpoint: 450,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    dots: true
                }
            }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
        });

    },

    // Restaurant Slider
    restaurantSlider: function() {
        $('.js-restaurant-slider').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            fade: true,
            autoplay: true,
            cssEase: 'linear'
        });
    },

    // Open fancybox modal
    openFancyBox: function() {
        $("a.js-fancybox").fancybox({
            margin : 10,
            padding: 0,
            helpers: {
                overlay: { // to prevent it from jumping on top of the page
                    locked: false
                }
            },
            afterShow: function() {
                $('.fancybox-wrap').swipe({
                    swipe : function(event, direction) {
                        if (direction === 'left' || direction === 'up') {
                            $.fancybox.prev( direction );
                        } else {
                            $.fancybox.next( direction );
                        }
                    }
                });

            },

            afterLoad : function() {
            }
        });
    },

    // Open room booking
    openRoomBooking: function() {
        $(".js-open-booking").click(function(){
            $(".js-booking-one").addClass("booking-one--active");
            $(".js-booking-two").slideDown('slow');
            $(".js-booking-two").addClass("booking-two--open");
        });
    },

    // Close room booking
    closeRoomBooking: function() {
        $(".js-close-booking").click(function(){
            $(".js-booking-two").slideUp('slow');
            $(".js-booking-two").removeClass("booking-two--open");
            $(".js-booking-one").removeClass("booking-one--active");
        });
    }
};

/**
 * Document ready (loaded)
 */
jQuery(document).ready(function() {
    // Init scripts
    hotelimpero.init();
});

/**
 *  Document load (in process of loading)
 */
jQuery(window).load(function() {
    // Do stuff
});
